<template>
  <div class="tag">
    <el-card class="box-card" shadow="never" v-if="tagList.length">
      <section-title><i class="el-icon-collection-tag">文章标签</i></section-title>
      <div class="text item">
        <span v-for="item in tagList" :key="item.id" >
          <router-link :to="`/tag/${item.name}/${item.id}`"> <el-button size="mini" class="tag-item" :type="item.input_type" plain>{{item.name}}</el-button></router-link>
        </span>
      </div>
    </el-card>
  </div>
</template>

<script>
  import sectionTitle from '@/components/section-title'
export default {
  name: 'TagComponent',
  props: {
    tagList: {
      type: Array
    }
  },
  components: {
    sectionTitle
  },
  created() {
  },
  methods: {
    tag(name) {
      this.$router.push({
        name: 'tag',
        params: {
          'name': name
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  background-color: rgba(255, 255, 255, 0.88);
  span {
    font-weight: bold;
  }
  .icon-icon_attachment {
    color: #3a95ed;
    font-size: 1.5rem;
  }
  .tag-item {
    margin: 4px 2px;
  }
}

@media (max-width: 1360px) {
  .box-card{
    height: 200px;
    .item {
      overflow:auto;
      height:200px
    }
  }
}

.el-button--mini, .el-button--mini.is-round {
  padding: 5px 10px;
}
</style>
