<template>
  <div class="friend">
    <el-card class="box-card" shadow="never"  v-if="recommend.length" >
      <div slot="header" class="d-flex align-items-center tip">
        <el-row :gutter="10">
          <section-title>
            <i class="el-icon-thumb">推荐文章</i>
          </section-title>
        </el-row>
      </div>
      <ul class="infinite-list">
        <div class="text item" v-for="item in recommend" :key="item.id" >
          <router-link :to="`/article/${item.id}`">
              <span style="line-height:30px; font-size:15px;">{{item.title}}</span>
          </router-link>
        </div>
      </ul>
    </el-card>
  </div>
</template>

<script>
  import sectionTitle from '@/components/section-title'
export default {
  name: 'RecommendComponent',
  data() {
    return {
    }
  },
  components: {
    sectionTitle
  },
  props: {
    recommend: {
      type: Array
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
  .box-card {
    background-color: rgba(255, 255, 255, 0.88);
    .item {
      padding: 10px 10px;
      border-top: 1px dashed #cccccc;
      text-align: left;
      a {
        text-decoration: none;
        margin: 0 auto;
      }
      color: #999999;
    }
    .item:hover {
      color: #3a95ed;
      background-color: #eee;
    }
    .left {
      float: left;
    }
    .rignt {
      float: right;
    }
  }

  .el-card ::v-deep .el-card__body {
    padding: 0 !important;
  }
</style>
