<template>
    <article class="article article-list" v-if="article.type == 2">
        <span @click="openExternalPage(article.other_url, article.id)">
            <div class="article-entry">
                <div class="feature">
                   <img :src="article.banner"/>
                </div>
                <div class="entry-header">
                    <div class="entry-title p-row">
                        <span v-if="article.is_top != 0" style="color:#ff6d6d;font-weight:600">[置顶] </span>
                        <span style="color:#ed9c28;font-weight:400">[转载] </span>
                        {{article.title}}
                    </div>
                    <div class="read-time p-row">
                        <i class="iconfont iconmeditor-time"></i> {{article.update_time | parseTime}}<i v-if="article.is_hot != 0" class="iconfont iconfire" style="margin-left: 5px;color: #ff6d6d;"></i>
                    </div>
                    <div class="p-row"><p class="summary">{{article.summary}}</p></div>
                </div>
                <footer class="entry-footer">
                    <el-row>
                        <el-col :xs="24" :sm="24" class="ft-row">
                            <span>
                                <i class="iconfont icon-icon_share comment"  @click.stop.prevent="docopy(`${article.id}`)"></i>
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <i class="iconfont icon-icon_favor comment" v-if="!is_star" @click.stop.prevent="favor(1)"></i>
                                <i class="iconfont icon-icon_favor_hover comment"  v-if="is_star" @click.stop.prevent="favor(0)"></i>
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <i class="iconfont icon-icon_comment comment"></i>{{article.comments_count}}
                            </span>
                            &nbsp;&nbsp;
                            <span>
                            <el-badge  :max="max"  :value = "article.views_count" size="small" class="item">
                                <i class="iconfont icon-icon_signal comment"></i>
                            </el-badge>
                            </span>
                            &nbsp;&nbsp;
                        </el-col>
                    </el-row>
                </footer>
            </div>
        </span>
    </article>
    <article class="article article-list"  @click="click(article.id)" v-else>
        <router-link :to="`/article/${article.id}`">
            <div class="article-entry">
                <div class="feature">
                    <img :src="article.banner"/>
                </div>
                <div class="entry-header">
                    <div class="entry-title p-row">
                        <span v-if="article.is_top != 0" style="color:#ff6d6d;font-weight:600">[置顶] </span>{{article.title}}
                    </div>
                    <div class="read-time p-row">
                        <i class="iconfont iconmeditor-time"></i> {{article.update_time | parseTime}}<i v-if="article.is_hot != 0" class="iconfont iconfire" style="margin-left: 5px;color: #ff6d6d;"></i>
                    </div>
                    <div class="p-row"><p class="summary">{{article.summary}}</p></div>
                </div>
                <footer class="entry-footer">
                    <el-row>
                        <el-col :xs="24" :sm="24" class="ft-row">
                            <span>
                                <i class="iconfont icon-icon_share comment"  @click.stop.prevent="docopy(`${article.id}`)"></i>
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <i class="iconfont icon-icon_favor comment" v-if="!is_star" @click.stop.prevent="favor(1)"></i>
                                <i class="iconfont icon-icon_favor_hover comment"  v-if="is_star" @click.stop.prevent="favor(0)"></i>
                            </span>
                            &nbsp;&nbsp;
                            <span>
                                <i class="iconfont icon-icon_comment comment"></i>{{article.comments_count}}
                            </span>
                            &nbsp;&nbsp;
                            <span>
                            <el-badge  :max="max"  :value = "article.views_count" size="small" class="item">
                                <i class="iconfont icon-icon_signal comment"></i>
                            </el-badge>
                            </span>
                            &nbsp;&nbsp;
                        </el-col>
                    </el-row>
                </footer>
            </div>
        </router-link>
    </article>
</template>

<script>
    import {click} from "@/api";

    export default {
        name: "ArticleComponent",
        props: {
            article: {
                type: Object
            }
        },
        data() {
            return {
                max: 99,
                is_star: false,
                msg: ''
            }
        },
        methods: {
            dataProcessing (val) {
                this.msg  = this.$url + '/article/' + val
            },
            docopy(val) {
                let _this = this
                this.dataProcessing(val)
                this.$copyText(this.msg).then(function () {
                    _this.$message.success(`文章地址已复制，赶紧粘贴分享吧!`);
                }, function (e) {
                    alert('Can not copy')
                    console.log(e)
                })
            },
            favor() {
                this.$message.error(`暂不支持收藏`);
            },
            openExternalPage(url, id) {
                click({location : 1001, location_id : id})
                window.open(url, '_blank');
            },
            click(id) {
                click({location : 1001, location_id : id})
            }
        },
    }
</script>

<style scoped lang="less">
    .article {
        position: relative;
        border-bottom: 1px dashed #ccc;
        padding: 10px;
    }

    .article:hover{
        background-color: #eee;
     }

    .article-entry {
        .entry-header {
            margin: 0 0 0 30%;
        }
        .p-row {
            margin-bottom: 10px;
        }
        .feature {
            img {
                max-height: 150px;
                float: left;
                width: 23%;
                object-fit: cover;
                border-radius: 1rem;
                padding: 2px;
                margin: 1%;
                border: 1px solid #DADADA;
                position: relative;
                transition: all 0.2s linear;
                overflow: hidden;
                &:hover {
                    transform: scale(1.1, 1.1);
                    filter: contrast(130%);
                }
            }
        }
        .entry-title {
            font-size: 21px;
            font-weight: 600;
            line-height: 50px;
            position: relative;
            z-index: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #737373;

            & a:hover {
                color: #8fd0cc;
            }
        }
        .read-time {
            font-size: 12px;
            color: #989898;
            letter-spacing: 1px;
            font-family: din, 'Hiragino Sans GB', 'Microsoft Yahei', Arial, sans-serif;
            display: flex;
            align-items: center;
        }
        p.summary {
            font-size: 15px;
            color: rgb(153, 153, 153);
            letter-spacing: 1px;
            line-height: 30px;
        }

        footer.entry-footer {
            margin: 0 0 0 30%;
            list-style: none;
            font-size: 0.8rem;
            color: #A0DAD0;
            .comment {
                font-size: 1.4rem;
            }
            .comment:hover {
                color: #409EFF;
                font-weight: bolder;
                text-shadow: 1px 1px 10px #409EFF;
            }
            .ft-row {
                margin-top: 10px;
                text-align: right;
                padding-right: 20px;
            }
        }

    }


    .article hr {
        height: 1px;
        margin: 0 auto;
        border: 0;
        background: #EFEFEF;
    }

    @media (max-width: 800px) {
        .article-entry {
            .feature img {
                width: 25%;
                height: 120px;
            }
            .entry-title{
                font-size: 16px;
                line-height: 30px;
            }
            p.summary {
                font-size: 14px;
                height: 30px;
                overflow: hidden;
            }
        }
        .article-list hr {
            margin-top: 20px;
        }
    }
</style>
