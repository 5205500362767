<template>
  <div class="friend">
    <el-card class="box-card" shadow="never"  v-if="friendList.length" >
      <div slot="header" class="d-flex align-items-center tip">
        <el-row :gutter="10">
          <section-title>
            <i class="el-icon-reading">推荐书签</i>
            <el-link class="more" type="info" :underline="false">
              <router-link to="/bookmark">更多<i class="el-icon-view el-icon--right"></i>
              </router-link>
            </el-link>
          </section-title>
        </el-row>
      </div>
      <ul class="infinite-list">
        <div class="text item" v-for="item in friendList" :key="item.id" >
          <a :href="item.path" :title="item.desc" target="_blank">
            <img v-if="item.icon_path" :src="item.icon_path" alt="" height="30px">
            <span v-else style="line-height: 30px">{{item.site_name}}</span>
          </a>
        </div>
      </ul>
    </el-card>
  </div>
</template>

<script>
  import sectionTitle from '@/components/section-title'
export default {
  name: 'FriendComponent',
  data() {
    return {
    }
  },
  components: {
    sectionTitle
  },
  props: {
    friendList: {
      type: Array
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
  .box-card {
    background-color: rgba(255, 255, 255, 0.68);
    .item {
      border-top: 1px dashed #cccccc;
      padding: 10px;
      text-align: center;
      a {
        text-decoration: none;
        margin: 0 auto;
      }
    }
    .item:hover {
       background-color: #eee;
    }
    span {
      font-weight: bold;
    }
    .left {
      float: left;
    }
    .rignt {
      float: right;
    }
    .icon-icon_link {
      color: #3a95ed;
      font-size: 1.5rem;
    }
    .icon-icon_book {
      color: #3a95ed;
      font-size: 1.5rem;
    }
  }



  /*background-color: rgba(241, 241, 241, 0.40);*/

  .el-card ::v-deep .el-card__body {
    padding: 0 !important;
  }

  .more {
    font-weight: bold;
    float: right;
  }
  .more:hover {
    color: #3a95ed;
  }
</style>
